@import "../../../../assets/styles/variables";

.ServicesSection {
    width: 100%;
    height: fit-content;
    padding-inline: 10%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    margin-top: 160px;
    background-color: $sub-backgorund-color-10;

    .section-title,
    .sub-title {
        font-size: 50px;
        z-index: 1;
        text-align: center;
        color: $text-color-1;
        margin-top: -80px;
    }

    .sub-title {
        font-size: 1.3rem;
        color: $text-color-2;
        margin-top: 5px;
        margin-bottom: 30px;
    }

    .section-container {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;

        .service-card {
            width: 22%;
            min-width: 250px;
            height: 300px;
            border-radius: 10px;
            margin-inline: 20px;
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;
            color: $text-color-2;

            i {
                font-size: 60px;
                color: $text-color-2;
                margin-bottom: 20px;
                transition: color 1s, transform 0.5s;
            }

            .service-title {
                font-size: 1.4rem;
                color: $text-color-1;
                text-align: center;
                margin-bottom: 1rem;
            }

            .service-details {
                line-height: 1.5rem;
                text-align: center;
                transition: color 1s, opacity 1s;

            }
        }
        .service-card:hover > i{
            color: $primary-color;
            transform: translateY(-2px) scale(1.05);
        }
        .service-card:hover > .service-details{
            color: $text-color-1;
            opacity: 0.5;
        }
    }

    @include breakpoint(868px) {
        .section-container {
            flex-direction: column;
        }

        .section-title {
            margin-top: -10px;
        }
    }

}

@include breakpoint(868px) {
    .ServicesSection {
        margin-top: 120px;
        padding-inline: 0%;
    }
}

@include breakpoint(1020px) {
    .ServicesSection {
        margin-top: 160px;
        padding-inline: 0%;
    }
}