@import "../assets//styles/variables";

.Submarine {
    --submarine-offset: 0px;
    position: absolute;
    top: 100%;
    left: 50%;
    width: 100px;
    height: 30px;
    z-index: 1000;
    pointer-events: none;

    .submarine-wrap {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .submarine-light {
        position: absolute;
        width: 60px;
        height: 50px;
        left: -55px;
        top: 5px;
        background-image: url(../assets/img/submarinelight.png);
        background-size: contain;
        background-repeat: no-repeat;
        opacity: 0;
    }

    @keyframes submarine-light-anim {
        0% {
            opacity: 0.5;
        }

        50% {
            opacity: 0.4;
        }

        98% {
            opacity: 0.6;
        }

        100% {
            opacity: 0;
        }
    }

    .circle-pulses {
        position: absolute;
        left: 35%;
        top: 30%;
    }

    .circle-pulse {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: rgb(23, 178, 199);
    }

    .circle-pulse-1 {
        animation: pulse-animation 3s ease-in infinite;
    }

    .circle-pulse-2 {
        animation: pulse-animation 3s 1s ease-in infinite;
    }

    .circle-pulse-3 {
        animation: pulse-animation 3s 2s ease-in infinite;
    }

    @keyframes pulse-animation {
        0% {
            transform: scale(1);
            opacity: 0.2;
        }

        100% {
            transform: scale(15);
            opacity: 0;
        }
    }

    .submarine-img {
        position: absolute;
    }


    .submarine-upload-bar {
        position: absolute;
        top: 13px;
        left: 45px;
        width: 0px;
        height: 4px;
        opacity: 0.3;
        border-radius: 5px;
        background-color: rgb(23, 178, 199);
        animation: submarine-upload-bar-anim 15s 0s forwards ease-in;
    }

    @keyframes submarine-upload-bar-anim {
        0% {
            width: 0px;
        }

        100% {
            width: 20px;
        }
    }

    @keyframes submarine-upload-bar-r-anim {
        0% {
            width: 20px;
        }

        100% {
            width: 0px;
        }
    }

    .submarine-move-land {
        animation: submarine-move-land-anim 10s ease forwards;

    }

    .submarine-upload {
        //animation: submarine-move-up-anim 10s ease infinite forwards;
    }

    @keyframes submarine-move-land-anim {
        0% {
            transform: rotate(0deg);
            right: 0;
        }

        50% {
            transform: rotate(90deg);
        }

        100% {
            right: calc(-100px - var(--submarine-offset));
            transform: rotate(90deg);
        }
    }

    @keyframes submarine-move-up-anim {
        0% {
            top: 90%;
            left: 50%;
            transform: rotate(0deg);
        }

        10% {
            top: 98%;
            left: 40%;
            transform: rotate(-45deg);
        }

        20% {
            top: 100%;
            left: 35%;
            transform: rotate(-85deg);
        }

        50% {
            top: 250%;
            left: 35%;
            transform: rotate(-90deg);
        }
    }
}

.submarine-move {
    //animation: submarine-move-down-anim 150s ease infinite forwards;
}

@keyframes submarine-move-down-anim {
    0% {
        top: 100%;
        left: 50%;
        transform: rotate(0deg);
    }

    5% {
        top: 110%;
        left: 40%;
        transform: rotate(-45deg);
    }

    12% {
        top: 115%;
        left: calc(30% + 80px);
        transform: rotate(-90deg);
    }

    30% {
        top: 250%;
        left: calc(30% + 80px);
        transform: rotate(-92deg);
    }

    40% {
        transform: scaleX(1) rotate(-90deg);
        top: calc(250% + 200px);
        transform: rotate(0deg);
    }

    40.1% {
        top: calc(250% + 200px);
        left: calc(30% + 80px);
        transform: rotate(0deg);
    }

    45% {
        top: calc(250% + 200px);
        left: calc(30% + 80px);
        transform: rotate(0deg);

    }

    60% {
        top: 230%;
        left: calc(30% + 80px);
        transform: rotate(88deg);
    }

    85% {
        top: 115%;
        left: calc(30% + 80px);
        transform: rotate(88deg);
    }

    95% {
        top: 105%;
        left: 45%;
        transform: rotate(125deg);
    }

    100% {
        top: 100%;
        left: 50%;
        transform: rotate(0deg);
    }
}

@include breakpoint(1300px) {
    .Submarine {
        top: 105%;
        left: 50%;
    }

    @keyframes submarine-move-down-anim {
        0% {
            top: 105%;
            left: 50%;
            transform: rotate(0deg);
        }

        5% {
            top: 110%;
            left: 40%;
            transform: rotate(-45deg);
        }

        12% {
            top: 115%;
            left: calc(30% + 80px);
            transform: rotate(-90deg);
        }

        30% {
            top: 250%;
            left: calc(30% + 80px);
            transform: rotate(-92deg);
        }

        40% {
            transform: scaleX(1) rotate(-90deg);
            top: calc(250% + 200px);
            transform: rotate(0deg);
        }

        40.1% {
            top: calc(250% + 200px);
            left: calc(30% + 80px);
            transform: rotate(0deg);
        }

        45% {
            top: calc(250% + 200px);
            left: calc(30% + 80px);
            transform: rotate(0deg);

        }

        60% {
            top: 230%;
            left: calc(30% + 80px);
            transform: rotate(88deg);
        }

        85% {
            top: 115%;
            left: calc(30% + 80px);
            transform: rotate(88deg);
        }

        95% {
            top: 105%;
            left: 45%;
            transform: rotate(125deg);
        }

        100% {
            top: 105%;
            left: 50%;
            transform: rotate(0deg);
        }
    }
}

// @include breakpoint(1020px) {
//     .Submarine {
//         top: 105%;
//         left: 30%;
//     }

//     @keyframes submarine-move-down-anim {
//         0% {
//             top: 105%;
//             left: 30%;
//             transform: rotate(0deg);
//         }

//         5% {
//             top: 110%;
//             left: 8%;
//             transform: rotate(-45deg);
//         }

//         12% {
//             top: 115%;
//             left: calc(0% - 20px);
//             transform: rotate(-90deg);
//         }

//         25% {
//             top: 260%;
//             left: calc(0% - 20px);
//             transform: rotate(-92deg);
//         }

//         40% {
//             transform: scaleX(1) rotate(-90deg);
//             top: calc(300% + 200px);
//             transform: rotate(-142deg);
//         }

//         // 40.1% {
//         //     top: calc(300% + 200px);
//         //     left: calc(30% + 80px);
//         //     transform: rotate(132deg);
//         // }

//         47% {
//             top: calc(300% + 200px);
//             left: calc(30% + 80px);
//             transform: rotate(0deg);

//         }

//         60% {
//             top: 260%;
//             left: calc(0% - 20px);
//             transform: rotate(88deg);
//         }

//         85% {
//             top: 115%;
//             left: calc(0% - 20px);
//             transform: rotate(88deg);
//         }

//         90% {
//             top: 105%;
//             left: 8%;
//             transform: rotate(125deg);
//         }

//         100% {
//             top: 105%;
//             left: 30%;
//             transform: rotate(0deg);
//         }
//     }
// }

@include breakpoint(1020px) {
    .Submarine {
        opacity: 0;
    }
}