@import "../../../../assets/styles/variables";

.AboutSection {
    width: 100wh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    color: $text-color-1;
    //background: linear-gradient(to bottom, #20202000, #20202081 5%, #202020 30%, #202020 75%);
    // background-color: $sub-backgorund-color-3;
    z-index: 2;
    clip-path: polygon(0 0, 0 84%, 0 100%, 19% 98%, 32% 94%, 53% 93%, 63% 95%, 78% 98%, 91% 98%, 100% 98%, 100% 0);

    .tunelback-img {
        position: absolute;
        width: 100%;
        top: 100%;
        left: 0;
        display: flex;
        transform: translateY(-0.3px);
        z-index: -1;
    }

    .section-container {
        width: 100%;
        height: 100%;
        display: flex;
        position: relative;

        .about-container {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            position: relative;

            .about-card {
                width: 30%;
                height: 100%;
                z-index: 1;
                overflow: hidden;
                align-items: center;
                display: flex;
                flex-direction: column;
                //position: relative;
                background-color: $sub-backgorund-color-7;
                padding-left: 5%;

                .react-tooltip{
                    border: 1px solid $primary-color;
                }

                .react-tooltip-arrow{
                    border-left: 1px solid $primary-color;
                    border-bottom: 1px solid $primary-color;
                }

                .div-wrapper {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                }

                .section-title {
                    font-size: 50px;
                    z-index: 1;
                    text-align: left;
                    margin-bottom: 40px;

                    span {
                        color: $primary-color;
                    }
                }

                .pro-pic {
                    border-radius: 100%;
                    cursor: help;

                    img {
                        width: 100%;
                        aspect-ratio: 1/1;
                        pointer-events: none;
                        border: 10px solid $primary-color;
                        position: relative;
                        border-radius: 100%;
                        object-fit: cover;
                    }

                    // &:hover {
                    //     &::before {
                    //         content: "Hi.. This is me 😃";
                    //         position: absolute;
                    //         height: 55px;
                    //         top: 150px;
                    //         left: 28%;
                    //         color: $text-color-1;
                    //         //background-color: $sub-backgorund-color-8;
                    //         //border-radius: 50px;
                    //         padding-top: 7px;
                    //         padding-bottom: 5px;
                    //         padding-right: 10px;
                    //         padding-left: 40px;
                    //         z-index: 100;
                    //         transition: all 1s;
                    //         //border: 0.5px solid $primary-color;
                    //         background-image: url(../../../../assets/img/dialogue.svg);
                    //         background-size: 100%;
                    //         background-repeat: no-repeat;
                    //         opacity: 0.8;
                    //     }
                    // }
                }

                .column {
                    justify-content: space-between;
                    padding-block: 15px;
                    height: 100%;
                }

                .details {
                    margin-top: 10px;
                    color: black;
                    display: flex;
                    flex-direction: column;
                    font-size: 18px;

                    .detail-name {
                        font-weight: bold;
                        margin-bottom: 10px;
                        margin-right: 10px;
                    }
                }

                .rel-links {
                    margin-top: 40px;
                    margin-bottom: 100px;

                    ul {
                        display: flex;
                        list-style: none;

                        a {
                            text-decoration: none;
                            color: $primary-color;
                        }

                        li {
                            margin-inline: 10px;
                            font-size: 1.8rem;
                            color: $primary-color;

                            i {
                                transition: transform 0.3s;

                                &:hover {
                                    transform: scale(1.2);
                                }
                            }
                        }
                    }
                }
            }

            .tunel-img {
                height: 100%;
                width: 150px;
                display: flex;
                background-size: cover;
                background-repeat: no-repeat;
            }

            .tunell-img {
                height: 100%;
                background-image: url(../../../../assets/img/tunell.svg);
            }

            .tunelr-img {
                height: 100%;
                width: 150px;
                display: flex;
                background-size: cover;
                background-repeat: no-repeat;
                margin-left: 50px;
                background-image: url(../../../../assets/img/tunelr.svg);
                background-position: top;

            }

            .about-content-wrapper {
                width: 70%;
                height: 100%;
                display: flex;
            }

            .about-content {
                width: 100%;
                height: 100%;
                background-color: $sub-backgorund-color-7;

                .div-wrapper {
                    width: 100%;
                    height: 100%;
                    padding-left: 20px;
                    line-height: 30px;
                    font-weight: lighter;
                    font-family: roboto;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding-right: 10%;
                }

                


                .min-about-card {
                    width: 100%;
                    height: 380px;
                    z-index: 1;
                    overflow: hidden;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    position: relative;
                    flex-direction: column;
                    margin-bottom: 20px;
                    margin-top: 20px;
                    display: none;

                    .section-title {
                        font-size: 50px;
                        z-index: 1;
                        text-align: center;
                        margin-bottom: 50px;

                        span {
                            color: $primary-color;
                        }
                    }

                    .propic-wrap {
                        width: 50%;
                        height: 70%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                    }

                    .pro-pic {
                        height: calc(100% - 3rem);

                        img {
                            height: 100%;
                            aspect-ratio: 1/1;
                            pointer-events: none;
                            border: 10px solid $primary-color;
                            position: relative;
                            border-radius: 100%;
                            object-fit: cover;
                        }

                    }

                    .rel-links {
                        margin-top: 1rem;
                        height: 2rem;
                        width: 170px;

                        ul {
                            padding-block: 20px;
                            display: flex;
                            list-style: none;
                            height: 100%;
                            align-items: center;
                            justify-content: space-around;

                            a {
                                text-decoration: none;
                                color: $primary-color;
                                margin-block: 10px;
                            }

                            li {
                                font-size: 1.8rem;
                                color: $primary-color;

                                i {
                                    transition: transform 0.3s;

                                    &:hover {
                                        transform: scale(1.2);
                                    }
                                }
                            }
                        }
                    }
                }

                .content-sub-title {
                    width: 100%;
                    display: flex;
                    text-align: left;
                    font-size: 2rem;
                    margin-bottom: 1.5rem;

                    span {
                        color: $primary-color;
                    }
                }

                .content-para {
                    font-size: 1.3rem;
                    line-height: 2.3rem;
                    margin-bottom: 20px;
                }

                @include breakpoint(1332px) {
                    .content-para {
                        font-size: 1.2rem;
                        line-height: 2rem;
                    }
                }

                @include breakpoint(1176px) {
                    .content-para {
                        font-size: 1rem;
                        line-height: 1.8rem;
                    }
                }
            }

            @include breakpoint(1020px) {
                .about-content-wrapper {
                    width: 100%;
                    padding-top: 0%;
                    justify-content: center;

                    .min-about-card {
                        display: flex;
                    }

                    .tunelr-img {
                        height: auto;
                    }

                    .content-sub-title {
                        font-size: 1.5rem;
                        margin-bottom: 5px;
                    }

                    .content-para {
                        line-height: 2rem;
                        font-size: 1.1rem;
                        margin-bottom: 80px;
                    }
                }

                .about-card {
                    display: none;
                }

                .tunell-img {
                    display: none;
                }

                .about-content-wrapper {
                    width: 100%;
                }
            }

            @include breakpoint(750px) {
                .about-content {
                    padding-inline: 10px;
                    text-align: justify;
                }

                .tunelr-img {
                    display: none;
                }
            }
        }
    }

    #skills {
        width: 10px;
        height: 10px;
        position: absolute;
        bottom: 40px;
    }
}

@include breakpoint(1020px) {
    .AboutSection {
        height: fit-content;
        clip-path: polygon(0 0, 0 84%, 0 70%, 19% 98%, 32% calc(100% - 50px), 53% calc(100% - 50px), 73% 95%, 98% 98%, 91% 98%, 100% 98%, 100% 0);


        .about-container {
            align-items: flex-start !important;
        }
    }
}

@include breakpoint(750px) {
    .AboutSection {
        height: fit-content;
        clip-path: polygon(0 0, 0 84%, 0 100%, 19% 98%, 32% calc(100% - 50px), 53% calc(100% - 50px), 73% calc(100% - 50px), 98% 98%, 91% 98%, 100% 98%, 100% 0);

    }

}