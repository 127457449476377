@import "../../assets/styles/variables";
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:ital,wght@0,600;0,700;1,400;1,500;1,600&family=Julee&family=Magra:wght@400;700&family=Roboto:wght@300;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Signika+Negative:wght@300;400;500;600;700&display=swap');

.Home {
    width: 100%;
    height: 100%;
    font-family: 'Signika Negative', sans-serif;
    overflow: hidden;
    position: relative;
    overflow-y: auto;
}

.m {
    width: 100%;
    height: 100%;
    background-color: red;
}

.s {
    width: 100%;
    height: 100%;
    background-color: green;
}

.Header {
    z-index: 1000;
}

img {
    pointer-events: none;
}
