@import "../assets//styles/variables";

.Turtle {
    position: absolute;
    left: 0%;
    top: 20%;
    transform-origin: center;

    .turtle {
        animation: turtle-fleg-anim 10s ease infinite;
    }

    .turtle-head {
        animation: turtle-head-anim 3s ease-out infinite;
    }

    .turtle-fleg {
        animation: turtle-fleg-anim 5s ease-out infinite;
        transform-origin: top center;
    }

    .turtle-bleg {
        animation: turtle-bleg-anim 5s ease-out infinite;
        transform-origin: top center;
    }

    .turtle-body {}

    @keyframes turtle-fleg-anim {
        0% {
            transform: rotateZ(0deg) translateY(-1px);
        }

        30% {
            transform: rotateZ(5deg) translateY(-3px);
        }

        60% {
            transform: rotateZ(-5deg) translateY(-3px);
        }

        100% {
            transform: rotateZ(0deg) translateY(-1px);
        }
    }

    @keyframes turtle-bleg-anim {
        0% {
            transform: rotateZ(0deg);
        }

        30% {
            transform: rotateZ(2deg);
        }

        60% {
            transform: rotateZ(-2deg);
        }

        100% {
            transform: rotateZ(0deg);
        }
    }

    @keyframes turtle-head-anim {
        0% {
            transform: rotateZ(0deg);
        }

        30% {
            transform: rotateZ(1deg);
        }

        60% {
            transform: rotateZ(-1deg);
        }

        100% {
            transform: rotateZ(0deg);
        }
    }
}