@import "../../../assets/styles/variables";

.Land {
    width: 100%;
    height: fit-content;
    overflow-y: hidden;
    overflow-x: hidden;
    background-color: $sub-backgorund-color-3;
    display: flex;
    flex-direction: column;

    .submarine {
        --submarine-offset: 0px;
        position: absolute;
        top: 100%;
        left: 50%;
        width: 100px;
        height: 30px;
        z-index: 1000;

        .submarine-wrap {
            position: relative;
        }

        .submarine-img {
            position: absolute;
        }

        .submarine-upload-bar {
            position: absolute;
            top: 13px;
            left: 45px;
            width: 0px;
            height: 4px;
            opacity: 0.3;
            border-radius: 5px;
            background-color: $primary-color;
            animation: submarine-upload-bar-anim 10s 3s forwards ease-in;
        }

        @keyframes submarine-upload-bar-anim {
            0% {
                width: 0px;
            }

            100% {
                width: 20px;
            }
        }

        .submarine-move-land {
            animation: submarine-move-land-anim 10s ease forwards;

        }

        .submarine-upload {
            //animation: submarine-move-up-anim 10s ease infinite forwards;
        }

        @keyframes submarine-move-land-anim {
            0% {
                transform: rotate(0deg);
                right: 0;
            }

            50% {
                transform: rotate(90deg);
            }

            100% {
                right: calc(-100px - var(--submarine-offset));
                transform: rotate(90deg);
            }
        }

        @keyframes submarine-move-up-anim {
            0% {
                top: 90%;
                left: 50%;
                transform: rotate(0deg);
            }

            10% {
                top: 98%;
                left: 40%;
                transform: rotate(-45deg);
            }

            20% {
                top: 100%;
                left: 35%;
                transform: rotate(-85deg);
            }

            50% {
                top: 250%;
                left: 35%;
                transform: rotate(-90deg);
            }
        }
    }

    .submarine-move {
        //animation: submarine-move-down-anim 150s ease infinite forwards;
    }

    @keyframes submarine-move-down-anim {
        0% {
            top: 100%;
            left: 50%;
            transform: rotate(0deg);
        }

        5% {
            top: 115%;
            left: 40%;
            transform: rotate(-45deg);
        }

        12% {
            top: 120%;
            left: calc(30% + 90px);
            transform: rotate(-92deg);
        }

        30% {
            top: 250%;
            left: calc(30% + 90px);
            transform: rotate(-92deg);
        }

        33% {
            transform: rotate(-45deg)
        }

        47% {
            left: -200px;
            top: 270%;
            transform: rotate(0deg);
        }

        48% {
            transform: scaleX(1);
        }

        49% {
            transform: scaleX(-1);
        }

        62% {
            top: 240%;
            left: calc(30% + 105px);
            transform: scaleX(-1) rotate(92deg);


        }

        85% {
            top: 115%;
            left: calc(30% + 105px);
            transform: scaleX(-1) rotate(92deg);
        }

        95% {
            top: 105%;
            left: 45%;
            transform: scaleX(-1) rotate(30deg);
        }

        100% {
            top: 100%;
            left: 50%;
            transform: rotate(0deg);
        }
    }



    .skillsbackdrop-img {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
        opacity: 0.7;
        pointer-events: none;
        z-index: 0;
        background-image: url(../../../assets/img/skillsbackdrop1.jpg);
        background-size: cover;
        background-repeat: repeat-y;
        filter: blur(5px);
        // animation: skew-card-anim 6s infinite;
        // transform: skew;
        // animation-direction: alternate;
    }

    @keyframes skew-card-anim {
        0% {
            transform: skewX(10deg) translate(0px);
        }

        100% {
            transform: skewX(-10deg) translate(0px);
        }
    }

    .AboutTop {
        width: 100vw;
        height: 200px;
        position: relative;
        z-index: 100;

        .about-top-grass {
            position: absolute;
            width: 15%;
            height: 50px;
            right: 38%;
            bottom: -2px;
            background-image: url(../../../assets/img/grass.png);
            background-position: left bottom;
            background-repeat: repeat-x;
            z-index: -1;
            transform-origin: bottom center;
            transition: width 0.1s, right 0.1s;
            animation: top-grass-skew-anim 6s infinite linear alternate;
        }
        @include breakpoint(1380px) {
            .about-top-grass {
                display: flex !important;
                width: 13% !important;
                height: 50px;
                right: 40% !important;
            }
        }
        @include breakpoint(1350px) {
            .about-top-grass {
                display: flex !important;
                width: 12% !important;
                height: 50px;
                right: 41% !important;
            }
        }
        @include breakpoint(1280px) {
            .about-top-grass {
                display: flex !important;
                width: 10% !important;
                height: 50px;
                right: 43% !important;
            }
        }
        @include breakpoint(1250px) {
            .about-top-grass {
                display: flex !important;
                width: 8% !important;
                height: 50px;
                right: 44% !important;
            }
        }
        @include breakpoint(1200px) {
            .about-top-grass {
                display: flex !important;
                width: 5% !important;
                height: 50px;
                right: 47% !important;
            }
        }
        @include breakpoint(1110px) {
            .about-top-grass {
                display: none !important;
            }
        }
        @include breakpoint(1020px) {
            .about-top-grass {
                display: flex !important;
                width: 30% !important;
                left: 14% !important;
            }
        }
        @include breakpoint(915px) {
            .about-top-grass {
                display: flex !important;
                width: 23% !important;
                left: 15% !important;
            }
        }
        @include breakpoint(850px) {
            .about-top-grass {
                display: flex !important;
                width: 18% !important;
                left: 16% !important;
            }
        }
        @include breakpoint(820px) {
            .about-top-grass {
                display: flex !important;
                width: 15% !important;
                left: 17% !important;
            }
        }
        @include breakpoint(790px) {
            .about-top-grass {
                display: flex !important;
                width: 10% !important;
                left: 18% !important;
            }
        }
        @keyframes top-grass-skew-anim {
            0% {
                transform: skewX(20deg);
            }

            100% {
                transform: skewX(-20deg);
            }
        }

        #about {
            width: 10px;
            height: 10px;
            position: absolute;
            bottom: 40px;
        }

        .citybottom-img {
            height: 203px;
            position: absolute;
            top: 0;
            right: 0;
            transform: translateY(-2px);
        }

        .aboutmetopl-img {
            width: 30%;
            height: 65%;
            position: absolute;
            left: 0;
            bottom: 0;
            background-image: url(../../../assets/img/aboutmetopl.svg);
            background-size: cover;
            background-position: right;
        }

        .aboutmetopr-img {
            height: 60px;
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translateY(0.5px);
            display: none;
        }

        .turtle1 {
            animation: turtle1-move 90s infinite linear;
        }

        .turtle2 {
            animation: turtle1-move 90s 3s infinite linear;

            svg {
                width: 30px;
            }
        }

        @keyframes turtle1-move {
            0% {
                left: 0%;
                top: 20%;
                transform: scaleX(1) rotateZ(30deg);
            }

            25% {
                top: 35%;
                left: 15%;
            }

            50% {
                left: 48%;
                top: 90%;
                transform: rotateZ(45deg) scaleX(1);

            }

            50.1% {
                left: 48%;
                top: 90%;
                transform: scaleX(-1) rotateZ(0deg);

            }

            60% {
                transform: rotateZ(15deg) scaleX(-1);
            }

            75% {
                left: 30%;
                top: 0%;
                transform: scaleX(-1)
            }

            99% {
                left: 0%;
                top: 20%;
                transform: scaleX(-1) rotateZ(30deg);
            }

            100% {
                left: 0%;
                top: 20%;
                transform: scaleX(1) rotateZ(30deg);
            }
        }
        @keyframes turtle2-move {
            0% {
                left: 0%;
                top: 20%;
                transform: scaleX(1) rotateZ(30deg);
            }

            25% {
                top: 45%;
                left: 10%;
            }

            50% {
                top: 90%;
                left: 25%;
                transform: scaleX(1);
            }
            50.1% {
                top: 90%;
                left: 25%;
                transform: scaleX(-1) rotateZ(5deg);
            }

            100% {
                left: 0%;
                top: 20%;
                transform: scaleX(-1) rotateZ(10deg);
            }
        }

        @include breakpoint(1020px) {
            .aboutmetopl-img {
                display: none;
            }
            .turtle1{
            animation: turtle2-move 50s infinite linear;
                
            }
            .turtle2{
            animation: turtle2-move 50s 3s infinite linear;

            }
        }
    }

    @include breakpoint(750px) {
        .AboutTop {
            height: 10px;
            display: none;
        }
    }

    .s {
        width: 100%;
        height: 100%;
    }
}