@import "../assets//styles/variables";
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:ital,wght@0,600;0,700;1,400;1,500;1,600&family=Julee&family=Magra:wght@400;700&family=Roboto:wght@300;500;700;900&display=swap');

.gallery {
    display: flex;
    padding: 2px;
    transition: .3s;
    flex-direction: column;

    &:hover &__image {
        filter: grayscale(1);
    }

    &__column {
        display: flex;
        width: 25%;
    }

    &__link {
        margin: 2px;
        overflow: hidden;

        &:hover {
            .gallery__image {
                filter: grayscale(0);
            }

            .gallery__caption {
                opacity: 1;
            }
        }
    }

    &__thumb {
        position: relative;
    }

    &__image {
        display: block;
        width: 100%;
        transition: .3s;

        &:hover {
            transform: scale(1.1);
        }
    }

    &__caption {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 25px 15px 15px;
        width: 100%;
        font-family: 'Raleway', sans-serif;
        font-size: 16px;
        color: white;
        opacity: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, .5) 0%, rgba(255, 255, 255, 0) 100%);
        transition: .3s;
    }
}