@import "../../../../assets/styles/variables";

.ContactSection {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  position: relative;
  color: $text-color-1;
  // background: linear-gradient(180deg, $sub-backgorund-color-7 0%, $sub-backgorund-color-7 100%);
  z-index: 1;
  overflow: hidden;
  background-color: $sub-backgorund-color-6;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 3%, rgba(0, 0, 0, 0.5) 100%);

  .spaceship-container {
    height: 65%;
    aspect-ratio: 0.8/1;
    position: absolute;
    top: 18%;
    right: 0;
    background-color: $sub-backgorund-color-3;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    z-index: -1;

    .blackhole-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .blackhole {
      width: 85%;
      position: relative;
      aspect-ratio: 1/1;
      left: 50%;
      top: 52%;
      transform: translate(-50%, -50%);
      opacity: 0.5;
      z-index: 10;

      .bhouter-circle-2,
      .bhouter-circle-1 {
        width: 100%;
        aspect-ratio: 1/1;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .bhouter-circle-1 {
        width: 90%;
        filter: blur(2px);
      }

      .bhouter-circle-2 {
        filter: blur(5px);
      }

      .bhinner-circle-1 {
        width: 70%;
        aspect-ratio: 1/1;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border: 10px solid #dcb100;
        box-shadow: 0px 0px 50px 10px #e65e00;
        border-radius: 50%;
        filter: blur(1px);
      }

      .bhinner-circle-2 {
        width: calc(70% - 20px);
        aspect-ratio: 1/1;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border: 5px solid #e65e00;
        box-shadow: 0px 0px 0px 3px #e65e00;
        border-radius: 50%;
      }
    }

    .animate-blackhole {
      opacity: 1;
      animation: bh-anim4 3s ease-in forwards;

      .bhouter-circle-2,
      .bhouter-circle-1 {
      }

      .bhouter-circle-1 {
        animation: bh-anim1 30s linear infinite forwards;
      }

      .bhouter-circle-2 {
        animation: bh-anim1 60s linear infinite forwards;
      }

      .bhinner-circle-1 {
        animation: bh-anim2 2s linear infinite alternate forwards;
      }

      .bhinner-circle-2 {
      }

      @keyframes bh-anim1 {
        from {
          transform: translate(-50%, -50%) rotate(0deg);
        }

        to {
          transform: translate(-50%, -50%) rotate(360deg);
        }
      }

      @keyframes bh-anim2 {
        from {
          box-shadow: 0px 0px 30px 10px #e65e00;
        }

        to {
          box-shadow: 0px 0px 70px 10px #e65e00;
        }
      }

      @keyframes bh-anim4 {
        from {
          opacity: 0.5;
        }

        to {
          opacity: 1;
        }
      }

      .bhouter-circle-1,
      .bhouter-circle-2 {
        opacity: 1;
      }
    }

    .animate-blackhole-exit {
      animation: bh-anim3 6s ease-out forwards;

      @keyframes bh-anim3 {
        from {
          opacity: 1;
        }

        to {
          opacity: 0.5;
        }
      }

      // .bhouter-circle-1, .bhouter-circle-2{
      //     opacity: 0;
      // }
    }

    .spaceshiptop-img {
      width: 180%;
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(-70%);
    }

    .spaceshipbottom-img {
      width: 180%;
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateY(70%) scaleY(-1);
    }
  }

  .section-title {
    font-size: 50px;
    z-index: 1;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1rem;

    span {
      color: $primary-color;
    }
  }

  .section-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .contact-top-img {
      width: 100%;
      position: absolute;
      top: 100%;
      left: 0;
    }

    .contact-bottom-img {
      width: 100%;
      position: absolute;
      bottom: 0;
    }

    .top-container {
      width: 100%;
      height: fit-content;
      padding-left: 10%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $sub-backgorund-color-7;
      position: relative;

      p {
        width: 70%;
        color: $text-color-2;
        text-align: center;
      }

      .contact-details {
        width: 100%;
        font-size: 1.2rem;
        display: flex;
        margin-top: 1.8rem;

        .detail-card {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: start;

          p {
            color: $text-color-1;
          }

          i {
            margin-bottom: 0.5rem;
            font-size: 1.5rem;
            color: $primary-color;
          }
        }

        .detail-card:hover > i {
          animation: shake-anim 1s;
        }

        @keyframes shake-anim {
          0% {
            transform: translate(1px, 1px) rotate(0deg);
          }

          10% {
            transform: translate(-1px, -2px) rotate(-1deg);
          }

          20% {
            transform: translate(-3px, 0px) rotate(1deg);
          }

          30% {
            transform: translate(3px, 2px) rotate(0deg);
          }

          40% {
            transform: translate(1px, -1px) rotate(1deg);
          }

          50% {
            transform: translate(-1px, 2px) rotate(-1deg);
          }

          60% {
            transform: translate(-3px, 1px) rotate(0deg);
          }

          70% {
            transform: translate(3px, 1px) rotate(-1deg);
          }

          80% {
            transform: translate(-1px, -1px) rotate(1deg);
          }

          90% {
            transform: translate(1px, 2px) rotate(0deg);
          }

          100% {
            transform: translate(1px, -2px) rotate(-1deg);
          }
        }
      }

      @include breakpoint(868px) {
        .contact-details {
          flex-direction: column;

          .detail-card {
            margin-bottom: 50px;
          }
        }
      }
    }

    .bottom-container {
      width: 100%;
      min-height: 450px;
      margin-top: 110px;
      display: flex;
      justify-content: center;

      form {
        display: flex;
        flex-direction: column;
        width: 30%;
        min-width: 300px;
        position: relative;

        input[type="text"],
        textarea {
          width: 100%;
          background-color: $sub-backgorund-color-3;
          border: 0;
          margin-bottom: 0.8rem;
          color: $text-color-1;
          font-size: 1.1rem;
          padding-inline: 1rem;

          &::placeholder {
            color: $text-color-1;
          }

          &:focus {
            outline: none !important;
            border: 1px solid $sub-backgorund-color-1;
            border-bottom: 1px solid $primary-color;
            // box-shadow: 0 0 5px $sub-backgorund-color-1;
          }
        }

        input[type="text"] {
          height: 4rem;
        }

        textarea {
          height: 10rem;
          resize: none;
          padding: 1rem;
        }

        .message-send-btn {
          width: 100%;
          display: flex;

          .whatsapp-btn {
            width: 100%;
            display: flex;
            background-color: $primary-color;
            padding: 10px;
            text-align: center;
            justify-content: center;
            color: black;
            font-size: 18px;
            border: 0;
            cursor: pointer;

            i {
              font-size: 1.5rem;
              margin-left: 20px;
            }

            p {
              font-size: 1.2rem;
            }
          }
        }

        &::before {
          content: "";
          width: 20px;
          height: calc(100% + 200px);
          position: absolute;
          top: -20px;
          left: 50%;
          transform: translateX(-50%);
          background-color: $sub-backgorund-color-3;
          z-index: -1;
        }
      }
    }

    .right-container {
      width: 50%;
      height: 80%;
      display: flex;
      flex-direction: column;
      padding-top: 2rem;
      margin-left: auto;
      display: none;

      img {
        transform: translateX(30%);
        height: 100%;
        object-fit: contain;
      }

      .related-links {
        width: 50%;
        opacity: 0.95;
        color: $text-color-2;
        display: flex;
        font-size: 2.4rem;
        align-items: center;
        margin-top: 4rem;
        justify-content: space-around;
      }
    }

    .left-container {
      width: 40%;
      height: 100%;
      padding-left: 0rem;
      padding-bottom: 100px;

      p {
        color: $text-color-2;
        margin-bottom: 2rem;
        text-align: justify;
      }

      @keyframes form-anim {
        0% {
        }

        100% {
          transform: translateX(150%) scale(0) rotateZ(500deg);
        }
      }

      form {
        display: flex;
        flex-direction: column;

        label {
          font-size: 1.2rem;
          margin-bottom: 1rem;
        }

        input[type="text"],
        textarea {
          width: 100%;
          background-color: transparent;
          border: 0;
          margin-bottom: 2rem;
          border-bottom: 1px solid $primary-color;
          color: $text-color-1;
          font-size: 1.1rem;
          padding-inline: 1rem;

          &::placeholder {
            color: $text-color-1;
          }

          &:focus {
            outline: none !important;
            border: 1px solid $sub-backgorund-color-1;
            border-bottom: 1px solid $primary-color;
            // box-shadow: 0 0 5px $sub-backgorund-color-1;
          }
        }

        input[type="text"] {
          height: 3rem;
        }

        textarea {
          height: 10rem;
          resize: none;
          padding: 1rem;
        }

        input[type="submit"] {
          width: 100%;
          background-color: $primary-color;
          padding: 10px;
          text-align: center;
          color: black;
          font-size: 18px;
          border: 0;
        }
      }
    }

    @include breakpoint(1200px) {
      .left-container {
        width: 70%;
      }
    }

    @include breakpoint(880px) {
      .left-container {
        width: 100%;
      }
    }
  }

  @include breakpoint(880px) {
    .section-container {
      // padding-right: 10%;
    }
  }
  .loader {
    margin-top: 3px;
    margin-left: 10px;
    width: 20px;
    height: 20px;
    padding: 4px;
    aspect-ratio: 1;
    border-radius: 15px;
    background: #7c7511;
    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
  }
  @keyframes l3 {
    to {
      transform: rotate(1turn);
    }
  }
}

@include sm {
  .ContactSection {
    height: fit-content;
  }
}
