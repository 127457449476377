@import "../../../../assets/styles/variables";

.HomeSection {
    width: 100%;
    height: calc(100vh - 50px);
    display: flex;
    position: relative;
    z-index: 1000;
    background: linear-gradient(180deg, #1E1E1C 30.21%, rgba(23, 23, 21, 0.760417) 65.47%, rgba(16, 16, 15, 0.531085) 81.1%, rgba(0, 0, 0, 0) 100%), url(../../../../assets/img/homebackdrop.webp);
    background-size: cover;
    padding-inline: 10%;
    clip-path: polygon(4% 98%, 0 100%, 0 0, 100% 0, 100% 100%, 26% 100%, 23% 98%, 19% 100%, 15% 98%, 9% 100%);
    animation: water-anim-1 6s ease-in-out infinite;
    font-family: 'Magra', sans-serif;

    .flashit {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../../../../assets/img/homebackdrop.webp);
        background-size: cover;
        opacity: 0;
        animation: flash ease-out (random(7) + 4)+s infinite;
        pointer-events: none;
    }

    @keyframes flash {
        from {
            opacity: 0;
        }

        92% {
            opacity: 0;
        }

        93% {
            opacity: 0.6;
        }

        94% {
            opacity: 0.2;
        }

        96% {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }

    .city-img {
        position: absolute;
        bottom: 0px;
        right: 0;
        z-index: 1000;
        transition: backdrop-filter 0.5s ease;
        transform: translateY(5px);

        // &:hover {
        //     backdrop-filter: blur(1px);
        // }
    }

    .hero {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 100%;
        top: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            mix-blend-mode: lighten;
        }
    }

    .hero-r {
        margin-right: 60px;
    }

    .airship-img {
        position: absolute;
        bottom: 100px;
        z-index: 100;
        animation: aireship-anim 60s ease-in-out forwards, aireship-anim-2 6s ease-in-out infinite;

    }

    .firework-img {
        position: absolute;
        bottom: 20px;
        right: 20px;
        z-index: 1001;
        opacity: 0.1;
        display: none;
    }

    @keyframes aireship-anim-2 {

        0% {
            transform: translateY(0px);
        }

        50% {
            transform: translateY(-10px);
        }

        100% {
            transform: translateY(0px);
        }
    }

    @keyframes aireship-anim {

        0% {
            left: 5%;
        }

        100% {
            left: 80%;
        }

        50% {
            right: 30%;
        }
    }

    .hero-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        color: $text-color-1;
        padding-top: 100px;
        justify-content: center;
        padding-bottom: 10%;
        align-items: start;
        padding-left: 15%;
        z-index: 1000;


        .text-container {
            width: fit-content;
            position: relative;
            text-align: center;
            display: flex;
            animation: text-container-anim 60s infinite;

            @keyframes text-container-anim {
                0% {
                    transform: translate(0);
                }

                20% {
                    transform: translate(-5px, 5px);
                }

                40% {
                    transform: translate(-5px, -5px);
                }

                60% {
                    transform: translate(5px, 5px);
                }

                80% {
                    transform: translate(5px, -5px);
                }

                100% {
                    transform: translate(0);
                }
            }

            .left-container {}

            .right-container {
                position: relative;
                display: flex;
                align-items: start;
                background-color: red;
                display: none;

                p {
                    font-size: 1.2rem;
                    transform: rotateZ(-90deg);
                }
            }
        }

        .hero-title {
            width: calc(100% + 1rem);
            height: 15rem;
            font-size: 15rem;
            text-align: left;
            transform: translateX(-1rem);
            display: flex;
            align-items: end;

            .box {
                width: 50px;
                height: 12rem;
                margin-left: auto;
                font-size: 1.4rem;
                color: $text-color-2;
                border: 10px solid $primary-color;
                border-left: 0;
                border-bottom: 0;
                display: flex;
                align-items: self-end;
                transform: translateY(-18%);
                animation: text-anim 6s ease-in-out infinite;
                transform-origin: top right;
                position: relative;

                &::after {
                    width: 50px;
                    height: 40px;
                    background-color: $primary-color;
                    position: absolute;
                    top: 0px;
                    left: 0;
                    border-bottom-left-radius: 20px;
                }

                // p {
                //     width: fit-content;
                //     display: flex;
                //     white-space: nowrap;
                //     transform: rotateZ(-90deg)  translateY(50%) translateX(50%);
                // }
            }

            // span {
            //     color: $primary-color;
            // }
        }

        .hero-sub-title {
            margin-top: -2rem;
            font-size: 2.5rem;
            text-align: left;
            display: flex;
            align-items: center;

            span {
                position: relative;
                display: flex;
                font-size: 1.3rem;
                color: $text-color-2;
                margin-left: 0.5rem;
                margin-top: 1rem;
                transform-origin: left top;
                animation: text-anim 3s ease-in-out infinite;
            }

            .span-fly {
                //animation: text-anim-2 4s ease forwards;
            }



            @keyframes text-anim-2 {
                0% {
                    opacity: 1;
                    transform: translateX(0px) scale(1);
                }

                100% {
                    opacity: 0;
                    transform: translateX(200px) rotate(200deg) scale(0.1);
                }

            }



        }

        .hero-sub-title-2 {
            margin-top: -0.5rem;
            font-size: 4.5rem;
            text-align: left;
        }

        .hero-para {
            width: 100%;
            margin-top: 50px;
            font-size: 20px;
            color: $text-color-2;
            padding-inline: 15%;

            span {
                color: $primary-color;
            }
        }

        .download-cv-btn {
            width: 100%;
            display: inline-block;
            padding: 10px;
            border-radius: 0.1rem;
            color: $sub-backgorund-color-3;
            font-size: 1rem;
            letter-spacing: .1rem;
            transition: all .3s;
            position: relative;
            overflow: hidden;
            background-color: $primary-color;
            z-index: 1;
            text-decoration: none;

            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0%;
                height: 100%;
                background-color: darken($primary-color, 10%);
                transition: all 0.7s;
                border-radius: 0.1rem;
                z-index: -1;
            }

            &:hover {
                &:before {
                    width: 100%;
                }
            }

        }
    }

    .discover-more-btn {
        background-color: $primary-color;
        color: $sub-backgorund-color-3;
        width: 200px;
        height: 50px;
        margin-bottom: 50%;
        font-size: 1.2rem;
        border: 0;
        border-radius: 1rem;
    }

    nav {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 50%;
        display: none;

        ul {
            list-style: none;

            li {
                display: flex;
                margin-top: 10px;
                justify-content: center;
                font-size: 1.2rem;
                font-weight: lighter;
                cursor: pointer;
            }

            .active-nav-item {
                color: $primary-color;
            }
        }
    }

    @keyframes text-anim {

        0% {
            transform: rotateZ(0deg) translateY(-18%);
        }

        50% {
            transform: rotateZ(2deg) translateY(-18%);
        }

        100% {
            transform: rotateZ(0deg) translateY(-18%);
        }
    }

    @include breakpoint(750px) {
        .hero-content {
            align-items: center;
        }
    }

    @include breakpoint(1020px) {
        .hero-content {
            padding-left: 5%;
        }
    }
}

@include breakpoint(1230px) {
    .HomeSection {
        animation: water-anim-2 6s ease-in-out infinite;
    }
}
@include breakpoint(910px) {
    .HomeSection {
        animation: none;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}

@keyframes water-anim-1 {

    0%,
    100% {
        clip-path: polygon(7% 99%, 0 100%, 0 0, 100% 0, 100% 100%, 47% 100%, 39% 99%, 31% 100%, 22% calc(100% - 12px), 15% 100%);
    }

    50% {
        clip-path: polygon(6% 100%, 0 calc(100% - 10px), 0 0, 100% 0, 100% 100%, 46% 100%, 39% 100%, 31% calc(100% - 18px), 24% 100%, 15% calc(100% - 15px));
    }
}

@keyframes water-anim-2 {

    0%,
    100% {
        clip-path: polygon(3% 99%, 0 100%, 0 0, 100% 0, 100% 100%, 25% 100%, 20% 99%, 15% 100%, 10% calc(100% - 6px), 5% 100%);
    }

    50% {
        clip-path: polygon(2% 100%, 0 calc(100% - 5px), 0 0, 100% 0, 100% 100%, 27% 100%, 22% 100%, 17% calc(100% - 7px), 12% 100%, 5% calc(100% - 5px));
    }


}