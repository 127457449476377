@import "../../../../assets/styles/variables";

.SkillsSection {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
    color: $text-color-1;
    padding-top: 100px;
    padding-inline: 10%;
    //background: linear-gradient(179.29deg, #1E1E1C 23.42%, #000000 180.97%), #1E1E1C;
    z-index: 1;
    //background: linear-gradient(180.64deg, rgba(30, 29, 28, 0) 6.81%, rgba(132, 132, 132, 0.31) 84.95%);
    //clip-path: polygon(0% 100%, 100% 100%, 100% 9%, 93% 5%, 86% 4%, 79% 4%, 72% 4%, 68% 2%, 58% 2%, 47% 3%, 37% 2%, 28% 4%, 19% 6%, 9% 7%, 0 5%);

    .skills-top-bar {
        height: 500px;
        width: 100%;
        background-color: $sub-backgorund-color-7;
        clip-path: polygon(0 0, 0 84%, 0 100%, 19% 98%, 32% 94%, 53% 93%, 63% 95%, 78% 98%, 91% 98%, 100% 100%, 100% 65%, 100% 0);
        display: none;
    }

    .skillsbackdrop-img {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
        filter: blur(10px);
        opacity: 0.6;
        pointer-events: none;
        object-fit: contain;
    }

    .skillsbottom-img {
        width: 100%;
        position: absolute;
        bottom: -160px;
        left: 0;
        opacity: 1;
    }

    .mushrooms-img {
        width: 100%;
        position: absolute;
        bottom: -285px;
        left: 0;
        opacity: 1;
        transform: rotateZ(3deg);
        display: none;
    }

    .section-container {
        width: 100%;
        height: 100%;

        .skillsboard-img {
            position: absolute;
            top: -16px;
            left: 73%;
            z-index: 100;

            .section-title {
                font-size: 50px;
                z-index: 1;
                text-align: left;
                position: absolute;
                top: 25px;
                right: 33%;
                display: flex;


                span {
                    color: $primary-color;
                }
            }
        }

        @include breakpoint(1020px) {
            .skillsboard-img {
                top: -52px;
                left: 28%;
            }
        }

        @include breakpoint(750px) {
            .skillsboard-img {
                top: -51px;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .skills-nav {
            display: flex;
            margin-bottom: 50px;
            justify-content: center;
            font-family: 'Barlow Condensed', sans-serif;

            ul {
                list-style: none;
                display: flex;
                z-index: 1;

                li {
                    margin-left: 10px;
                    font-size: 20px;
                    padding: 3px;
                    padding-inline: 20px;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: all 0.5s;
                    border: 1px solid transparent;

                    &:hover {
                        border-color: $primary-color;
                    }
                }

                .active-skills-nav-item {
                    background-color: $primary-color;
                    color: black;
                }
            }
        }

        @include breakpoint(750px) {
            .skills-nav {
                margin-top: 20px;
            }
        }

        .skill-card-container {
            width: 100%;
            min-height: 610px;
            // display: grid;
            // grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            column-gap: 1rem;
            row-gap: 1rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding-bottom: 15%;
            z-index: 1;

            .skill-card {
                z-index: 1;
                width: 140px;
                height: 140px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 40px;
                padding-block: 10px;
                background-color: $sub-backgorund-color-9;
                transition: transform 1s;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 5px 5px 20px 10px rgba(0, 0, 0, 0.25);
                animation: skill-card-anim 0.5s ease-in;

                &:hover {
                    transform: rotateX(50deg);
                    border: 1px solid $sub-backgorund-color-8;

                    img {
                        transform: rotateX(0deg) scaleY(1.7) translateY(-20px) scaleX(1.1);
                    }

                    .card-title {
                        transform: scaleY(1.5) scaleX(1.1);
                    }
                }

                @keyframes skill-card-anim {
                    0% {
                        transform: scale(0);
                        opacity: 0;
                    }

                    100% {
                        transform: scale(1);
                        opacity: 1;
                    }
                }

                img {
                    transition: transform 1s;
                    height: 70%;
                }

                .card-title {
                    transition: transform 1s;
                    margin-top: 10px;
                    text-align: center;
                    font-size: 1.2rem;
                    z-index: 1;

                }
            }
        }
    }



}