@import "./breakpoints";

$backgorund-color: #1E1E1C; //#202020;
$primary-color: #F4C00C; //F4C00C
$sub-backgorund-color-1: #f4c20c74;
$sub-backgorund-color-2: #131212;
$sub-backgorund-color-3: black;
$sub-backgorund-color-4: rgb(246, 250, 251);
$sub-backgorund-color-5: rgb(209, 241, 227);
$sub-backgorund-color-6: #13121276;
$sub-backgorund-color-7: rgba(0, 0, 0, 0.732);
$sub-backgorund-color-8: #1e1e1ce1;
$sub-backgorund-color-9: #1e1e1c7e;
$sub-backgorund-color-10: #000000bb;
$border-color-1: #707070;
$border-color-2: rgb(204, 204, 204);
$border-color-3: rgba(0, 0, 0, 0.1);
$border-color-4: rgba(204, 204, 204, 0.315);
$border-color-5: rgb(170, 172, 171);
$text-color-1: white;
$text-color-2: rgb(112, 112, 112);
$text-color-3: rgb(87, 87, 87);

$box-size-1 : 50px