@import "./assets/styles/variables";


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: $primary-color;
  border-radius: 0.1em;
}

@include breakpoint(500px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

.modal-overlay {
  position: fixed;
  inset: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $sub-backgorund-color-7;
  backdrop-filter: blur(1px);
  z-index: 10000;

  .Modal {
    position: absolute;
    display: grid;
    place-content: center;
    overflow: auto;
    outline: none;
  }
}

.ReactModal__Overlay {
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--before-close {
  opacity: 0;

  .portfolio-details-modal {
    transition: transform 300ms, opacity 300ms;
    opacity: 0 !important;
    transform: scale(0) !important;
  }
}

.portfolio-details-modal {
  position: relative;
  width: 35vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: $sub-backgorund-color-2;
  border-radius: 10px;
  color: $text-color-1;
  overflow-x: hidden;
  transition: width 0.3s, height 0.3s;

  .portfolio-d-img {
    width: 100%;
    aspect-ratio: 19/10;
    overflow: hidden;
    margin-bottom: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
  }

  .portfolio-d-title {
    font-size: 1.2rem;
    margin-bottom: 10px;
    margin-inline: 20px;
  }

  .portfolio-d-details {
    font-size: 1rem;
    margin-inline: 20px;
    color: rgb(145, 145, 145);
  }

  .portfolio-d-tech-list {
    width: 100%;
    display: flex;
    margin-top: 25px;
    padding-inline: 20px;

    .portfolio-d-tech {
      width: 25px;
      margin-right: 5px;

      img {
        width: 100%;
      }

    }
  }

  .portfolio-d-link-list {
    display: flex;
    margin-left: auto;

    .portfolio-d-link-a {
      background-color: rgb(51, 51, 51);
      padding: 5px;
      padding-block: 3px;
    }

    .portfolio-d-link-b {}

    .portfolio-d-link {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      color: $text-color-1;
      margin-right: 10px;
      cursor: pointer;
      text-decoration: none;

      i {
        margin-right: 7px;
      }

      p {
        font-size: 0.8rem;
      }

      img {
        width: 100px;
      }
    }
  }

  .portfolio-d-close-btn {
    width: 80px;
    height: 25px;
    margin-top: 20px;
    margin-inline: auto;
    background-color: $primary-color;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    display: grid;
    place-content: center;
    transition: transform 0.5s;

    i {
      transform: translateY(3px);
      color: transparent;
    }

    &:hover {
      transform: translateY(-5px);
    }

    &:active {
      transform: scale(0.8);
    }
  }
}

@include breakpoint(1150px) {
  .portfolio-details-modal {
    width: 40vw;
  }
}

@include breakpoint(1020px) {
  .portfolio-details-modal {
    width: 45vw;
  }
}

@include breakpoint(850px) {
  .portfolio-details-modal {
    width: 55vw;
  }
}

@include breakpoint(650px) {
  .portfolio-details-modal {
    width: 65vw;
  }
}

@include breakpoint(550px) {
  .portfolio-details-modal {
    width: 75vw;
  }
}

@include breakpoint(500px) {
  .portfolio-details-modal {
    width: 90vw;
  }
}