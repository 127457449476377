@import "../assets/styles/variables";
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:ital,wght@0,600;0,700;1,400;1,500;1,600&family=Julee&family=Magra:wght@400;700&family=Roboto:wght@300;500;700;900&display=swap');

.Footer {
    background-color: $sub-backgorund-color-3;
    height: 100px;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: -10px;

    .rel-links {

        ul {
            display: flex;
            list-style: none;
            margin-bottom: 10px;

            li {
                margin-inline: 10px;
                font-size: 1.5rem;
                color: $text-color-2;

                i {
                    transition: transform 0.3s;

                    &:hover {
                        transform: scale(1.2);
                    }
                }
            }
        }
    }

    p {
        text-align: center;
        color: $text-color-2;
    }

    a {
        text-decoration: none;
        color: $primary-color;
        margin-block: 10px;
    }
}