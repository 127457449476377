@import "../assets/styles/variables";
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:ital,wght@0,600;0,700;1,400;1,500;1,600&family=Julee&family=Magra:wght@400;700&family=Roboto:wght@300;500;700;900&display=swap');

.SideNavBar {
    position: fixed;
    width: 40px;
    height: 248px;
    padding-block: 10px;
    top: 50%;
    left: 10px;
    background-color: $sub-backgorund-color-6;
    border-radius: 20px;
    transform: translateY(-50%);
    z-index: 1000;
    backdrop-filter: blur(10px);


    nav {
        width: 100%;
        height: 100%;

        ul {
            height: 100%;
            list-style: none;
            display: flex;
            flex-direction: column;
            justify-content: center;

            li {
                width: 100%;
                color: $text-color-3;
                font-size: 22px;
                text-align: center;
                margin-block: 5px;
            }

            .active-nav {
                li {
                    color: $primary-color;
                }
            }
        }
    }
}

@include breakpoint(500px) {
    .SideNavBar {
        width: 248px;
        height: 40px;
        top: 20px;
        left: 50%;
        transform: translateY(0%) translateX(-50%);
        padding-block: 0px;
        padding-inline: 10px;


        nav {
            ul {
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
            }
        }
    }
}