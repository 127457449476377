@import "../assets//styles/variables";
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:ital,wght@0,600;0,700;1,400;1,500;1,600&family=Julee&family=Magra:wght@400;700&family=Roboto:wght@300;500;700;900&display=swap');

.Header {
    width: 100%;
    height: 80px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 100px;
    color: $text-color-1;
    font-family: 'Magra', 'Roboto', sans-serif;

    .logo {
        font-size: 30px;
        display: flex;
        align-items: center;

        .logo-box {
            width: 30px;
            height: 30px;
            margin-right: 10px;
            background-color: $primary-color;
        }
    }

    nav {
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        // clip-path: polygon(0 0, 100% 0, 97% 100%, 3% 100%);
        color: $text-color-1;
        height: 50px;
        border-radius: 50px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        background-color: $backgorund-color;
        padding-inline: 10px;

        .nav-items {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
        }


        .nav-item {
            height: 40%;
            width: 100px;
            padding-inline: 20px;
            margin-inline: 15px;
            place-content: center;
            font-size: 20px;
            display: flex;
        }

        .nav-item-active {
            color: $primary-color;
        }
    }

    .short-nav {
        border-bottom: 1px solid $primary-color;
        transition: margin 0.2s;
        margin-bottom: 30px;
        // animation: short-nav-anim 0.2s;
    }

    @keyframes short-nav-anim {
        from {
            margin-bottom: 0;
        }

        to {
            margin-bottom: 30px;
        }
    }


    .contact-nav-btn {
        height: 40%;
        padding-inline: 20px;
        margin-inline: 15px;
        display: grid;
        place-content: center;
        color: $text-color-1;
        font-size: 20px;
        border-radius: 5px;
        border: 1px solid $primary-color;
    }
}