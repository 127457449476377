@import "./assets/styles/variables";

.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  scroll-behavior: smooth;
  background-color: $backgorund-color;
}

.row {
  display: flex;
  width: 100%;
}

.column {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.spacer-l {
  margin-left: auto;
}

.spacer-r {
  margin-right: auto;
}

.spacer-t {
  margin-top: auto;
}

.spacer-b {
  margin-bottom: auto;
}

.spacer-block {
  margin-block: auto;
}

.spacer-inline {
  margin-inline: auto;
}

button {
  cursor: pointer;
  border: 0;
}