@import "../../../../assets/styles/variables";

.PortfolioSection {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
    color: $text-color-1;
    padding-inline: 10%;
    padding-top: 200px;
    z-index: 1;
    //background: linear-gradient(180deg, rgba(0, 0, 0, 0) 7.89%, rgba(0, 0, 0, 0.4) 100%);

    .water-img {
        position: absolute;
        top: 150px;
        left: 0;
        z-index: 0;
        display: none;
    }

    .grassback-img {
        height: 90%;
        width: 90%;
        margin-inline: 5%;
        position: absolute;
        bottom: 10px;
        left: 0;
        opacity: 0.8;
        z-index: 0;
        background-image: url(../../../../assets/img/grassback.webp);
        background-position: bottom;
        background-size: 75%;
        background-repeat: repeat-x;
        transform-origin: bottom;
        animation: skew-anim 6s infinite;
        transform: skew;
        animation-direction: alternate;
        z-index: -1;
    }

    @keyframes skew-anim {
        0% {
            transform: skewX(2deg) translate(-1px);
        }

        100% {
            transform: skewX(-2deg) translate(1px);
        }
    }

    .portfoliobottom-img {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
    }

    .portfoliotop-img {
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0;
        z-index: 0;
    }

    .portfolioboard-img {
        position: absolute;
        top: 90px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;

        .card-chain {
            width: 10px;
            height: 50px;
            position: absolute;
            top: -55px;
            left: calc(50% + -5px);
            background-image: url(../../../../assets/img/chain.png);
            background-position: center;
            z-index: -1;
            display: none;
        }

        .portfolioboard-img-wrap {
            position: relative;
        }

        .hologram-img {
            position: absolute;
            top: -15px;
            left: 50%;
            transform: translateX(-50%);
        }

        .hologramlight-img {
            position: absolute;
            top: -7px;
            left: calc(50% + 3px);
            transform: translateX(-50%);
            animation: hologramlight-anim 4s infinite;
        }

        .section-title {
            position: absolute;
            font-size: 50px;
            z-index: 1;
            text-align: left;
            display: flex;
            top: 10px;
            left: 50px;
            animation: hologramlight-anim2 4s infinite;


            span {
                color: $primary-color;
            }

        }

        @keyframes hologramlight-anim {
            0% {
                opacity: 1;
            }

            50% {
                opacity: 1;
            }

            80% {
                opacity: 1;
            }

            97% {
                opacity: 1;
            }

            98% {
                opacity: 0.1;
            }
        }

        @keyframes hologramlight-anim2 {
            0% {
                opacity: 1;
            }

            50% {
                opacity: 1;
            }

            80% {
                opacity: 1;
            }

            97% {
                opacity: 1;
            }

            98% {
                opacity: 0.6;
            }
        }
    }

    @include breakpoint(1250px) {
        .portfolioboard-img {
            top: 80px
        }
    }

    .protfolio-nav {
        display: flex;
        justify-content: center;
        z-index: 1;
        font-family: 'Barlow Condensed', sans-serif;

        ul {
            list-style: none;
            display: flex;

            li {
                margin-left: 10px;
                font-size: 20px;
                padding: 3px;
                padding-inline: 20px;
                border-radius: 5px;
                cursor: pointer;
                transition: all 0.5s;
                border: 1px solid transparent;

                &:hover {
                    border-color: $primary-color;
                }
            }

            .active-portfolio-nav-item {
                background-color: $primary-color;
                color: black;
            }
        }
    }

    .section-container {
        width: 100%;
        min-height: 720px;
        height: fit-content;
        padding-bottom: 5rem;
        margin-top: 40px;
        position: relative;
        column-gap: 2rem;
        row-gap: 63px;
        display: flex;
        justify-content: center;
        align-items: start;
        flex-wrap: wrap;
        padding-bottom: 200px;
        // animation: skew-card-anim 6s infinite;
        // transform: skew;
        // animation-direction: alternate;
        overflow-y: hidden;
        margin-bottom: 5.5%;

        .portfolio-card {
            max-width: 380px;
            min-width: 300px;
            position: relative;
            cursor: pointer;
            transition: all 1s;

            // &:hover {
            //     animation: card-hover-anim 6s infinite;
            //     transform: skew;
            //     animation-direction: alternate;
            // }

            .more-btn {
                position: absolute;
                bottom: 10px;
                left: calc(50% - 20px);
                width: 50px;
                border-radius: 50%;
                aspect-ratio: 1/1;
                background-color: white;
                transition: bottom 1s ease-out;
                opacity: 0;
                display: grid;
                place-content: center;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
                z-index: 100 !important;
                i {
                    color: $sub-backgorund-color-2;
                    font-size: 1.5rem;
                }
            }

            &:hover>.more-btn {
                opacity: 0.8;
                bottom: 45px;
                z-index: 1;
            }

            &:hover>img {
                transform: scale(0.98);
                filter: grayscale(0);
            }

            &:hover>.card-title {
                opacity: 1;
                bottom: 0px;
            }

            &:hover {
                filter: grayscale(0) !important;
            }

            img {
                width: 100%;
                aspect-ratio: 19/11;
                object-fit: cover;
                transition: transform 0.3s ease-out, filter 0.8s ease-out;
                pointer-events: all;
                border-radius: 10px;
                box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
                filter: grayscale(0.8);
            }

            .card-title {
                position: absolute;
                bottom: 10px;
                width: 90%;
                height: 3rem;
                font-size: 1.1rem;
                padding: 10px;
                text-align: center;
                opacity: 0;
                transition: all 1s ease-out;
                text-shadow: 5px 5px 10px black;
                font-size: 1.2rem;
                z-index: 50;
                left: 50%;
                transform: translateX(-50%);
                //background-color: $sub-backgorund-color-7;
                border-radius: 10px;
                background: radial-gradient(50% 50% at 50% 50%, $sub-backgorund-color-7 0%, rgba(30, 30, 28, 0) 100%);
            }

            .card-chain {
                width: 10px;
                height: 506px;
                position: absolute;
                bottom: -495px;
                left: 50%;
                background-image: url(../../../../assets/img/chain.png);
                background-position: center;
                z-index: -2;
            }

            .card-desc {
                margin: 20px;
                margin-top: 0px;
                color: $text-color-2;
            }
        }

        @keyframes card-hover-anim {
            0% {
                transform: translateY(-5px);
            }

            100% {
                transform: translateY(5px);
            }
        }



        @keyframes skew-card-anim {
            0% {
                transform: skewX(1deg) translate(-5px);
            }

            100% {
                transform: skewX(-1deg) translate(5px);
            }
        }
    }
}